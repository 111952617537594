.edit-button-modal {
  border-radius: 12px;
  height: 350px;
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border: 1px solid black;
  box-shadow: 2px 2px 2px;
  background-color: rgb(241, 241, 241);
  font-family: "Source Sans Pro", sans-serif;
}

.edit-header {
  font-size: 20px;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 111;
  padding: 0;
  margin-top: 10px;
  margin-bottom: 0px;
}

.edit-textarea {
  resize: none;
  font-family: "Source Sans Pro", sans-serif;
  padding: 8px;
  width: 300px;
  max-width: 300px;
  height: 150px;
  max-height: 290px;
  background-color: rgb(255, 255, 255);
  color: black;
  border-bottom: 1px solid rgb(222, 222, 222);
}

.edit-button-submit {
  margin: 4px;
  border-radius: 4px;
  background-color: rgb(0, 166, 255);
  border: none;
  color: white;
  height: 30px;
  width: 150px;
  margin: 20px;
}

.edit-button-submit:hover {
  color: rgb(181, 181, 181);
  cursor: pointer;
}

.edit-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.edit-desc {
  padding: 5px;
}
