.profile-banner-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 200px;
  margin-bottom: 0px;
  padding-top: 0;
  margin-top: 0;
  background-image: url(https://images.unsplash.com/photo-1679246453872-258ee52433eb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80);
}

.profile-name {
  display: flex;
  font-size: 30px;
  margin-left: 13%;
  color: white;
  text-shadow: 1px 1px 0 rgb(32, 32, 32);
  font-weight: 100;
  padding-bottom: 10px;
  align-items: center;
}

.followers-dropdown,
.following-dropdown {
  display: flex;
  position: absolute;
  flex-direction: column;
  height: 200px;
  background-color: rgb(33, 33, 33);
  color: white;
  border: solid grey 2px;
  padding: 5px;
  width: 200px;
  overflow: scroll;
  z-index: 100;
  border-radius: 12px;
  font-family: "Source Sans Pro", sans-serif;
}

.followers-dropdown div,
.following-dropdown div {
  padding: 8px;
  cursor: pointer;
  /* border: 0.5px solid rgb(196, 196, 196); */
  height: auto;
}

.followers-dropdown div:hover,
.following-dropdown div:hover {
  color: rgb(0, 166, 255);
}

.profile-follow {
  display: flex;
  font-size: 16px;
  color: white;
  font-weight: 100;
  margin-left: 13.3%;
}

.profile-follow:hover {
  cursor: pointer;
  color: rgb(0, 166, 255);
}

.profile-following:hover {
  cursor: pointer;
  color: rgb(0, 166, 255);
}
.profile-body {
  background-color: rgb(232, 232, 232);
}

.profile-following {
  display: flex;
  font-size: 16px;
  color: white;
  font-weight: 100;
}

.follow-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-shadow: 2px 2px 0 rgb(50, 50, 50);
}

.add-image-button {
  margin: 4px;
  border-radius: 2px;
  border: none;
  background-color: rgb(0, 166, 255);
  color: white;
  height: 30px;
  width: 200px;
}

.add-image-button:hover,
.user-image-card-button:hover {
  cursor: pointer;
  color: rgb(195, 195, 195);
}

.modal {
  background-color: rgb(239, 239, 239);
  color: rgb(0, 0, 0);
}

.delete-button-model {
  border-radius: 4px;
  border: none;
  background-color: rgb(0, 166, 255);
  outline: none;
  box-shadow: none;
}

.delete-button-model:hover {
  box-shadow: 1px 1px 1px black;
}

.user-images-body {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.such-empty {
  font-size: 60px;
  font-weight: 100;
  font-family: Arial, Helvetica, sans-serif;
  color: rgb(150, 150, 150);
  text-align: center;
}

.try {
  color: rgb(150, 150, 150);
  margin-left: 190px;
  margin-top: 5px;
  font-family: Arial, Helvetica, sans-serif;
}

.user-image {
  padding: 3px;
}

.yes {
  background-color: rgb(39, 39, 39);
}

.yes:hover {
  background-color: rgb(177, 0, 0);
}

.info-profile {
  font-weight: bold;
}

.your-images {
  padding-left: 18px;
  font-size: 30px;
  font-weight: 1;
  font-family: Arial, Helvetica, sans-serif;
}

.img__description_layer {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  color: #fff;
  visibility: hidden;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.2s, visibility 0.2s;
}

.user-image:hover .img__description_layer {
  visibility: visible;
  opacity: 1;
}

.img__description {
  transition: 0.2s;
  transform: translateY(1em);
}

.user-image:hover .img__description {
  transform: translateY(0);
}

.user-image-card-button {
  margin: 4px;
  border-radius: 2px;
  background-color: rgb(0, 166, 255);
  border: none;
  color: white;
  height: 30px;
  width: 150px;
}

.profile-image-hover {
  cursor: pointer;
  height: 320px;
  width: 320px;
  border-radius: 3px;
  box-shadow: 0 0 2px 0 rgb(0, 0, 0);
}
.profile-image-hover:hover {
  cursor: pointer;
  opacity: 0.7;
}

.new-comment-button {
  color: rgb(0, 0, 0);
  border: none;
  padding-bottom: 7px;
  background-color: rgb(236, 236, 236);
  border-bottom: 1px solid rgb(205, 205, 205);
}
.new-comment-button:hover {
  cursor: pointer;
}

.black {
  color: rgb(0, 166, 255);
  font-size: 30px;
}

.black:hover {
  color: rgb(0, 130, 199);
}

.plus {
  font-size: 10px;
}

/*
@media screen and (max-width: 480px) {
  .copyright li {
    border: 0;
    display: block;
    line-height: 1.65em;
    margin: 0;
    padding: 0.5em 0;
  }
} */
@media (max-width: 700px) {
  .user-image {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .profile-image-hover {
    height: 300px;
    width: 300px;
    border-radius: 6px;
  }

  .user-product-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 8px;
  }
  .user-images-body {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .user-product-card {
    display: flex;
  }

  .img__description_layer {
    opacity: 1;
    visibility: visible;
  }
}
