.login-container {
  display: flex;
  height: 900px;
  background-size: cover; /* <------ */
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(https://images.unsplash.com/photo-1531966662811-c6501e46eda6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2515&q=80);
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}

.login-form {
  display: flex;
  flex-direction: column;
  height: 440px;
  width: 340px;
  background-color: rgba(255, 255, 255, 0.942);
  justify-content: space-between;
  align-items: center;
  border-radius: 12px;
}

.login-formmm {
  display: flex;
  flex-direction: column;
}

.login-form-input {
  height: 40px;
  width: 280px;
  border: 1px solid gray;
  border-radius: 4px;
  margin: 5px;
  padding: 5px;
}

.demo {
  color: rgb(105, 202, 255);
  border: none;
  background-color: rgba(255, 255, 255, 0);
  margin-bottom: 15px;
}

.demo:hover {
  cursor: pointer;
  color: rgb(179, 228, 255);
}

.login-form-input::placeholder {
  color: rgb(0, 166, 255);
}

.login-header {
  font-size: 20px;
  font-weight: 100;
}

.login-button {
  color: white;
  background-color: rgb(0, 166, 255);
  border-radius: 4px;
  padding: 5px;
  margin: 5px;
  border: none;
  font-size: 15px;
  height: 40px;
  width: 85%;
}

.signup-button {
  color: white;
  background-color: rgb(0, 166, 255);
  border-radius: 4px;
  padding: 5px;
  margin: 5px;
  border: none;
  font-size: 15px;
  height: 40px;
  width: 85%;
}

.login-button:hover,
.signup-button:hover {
  cursor: pointer;
  color: rgb(210, 210, 210);
  border: 2px solid rgb(255, 255, 255);
}

.errorz {
  color: red;
  list-style: none;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 13px;
}

.errorzz {
  color: red;
  list-style: none;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 13px;
}
.profile-info {
  color: white;
}
