html {
  background-color: rgb(36, 36, 36);
}

.profile-dropdown {
  display: flex;
  flex-direction: column;
  position: relative;
  right: 140px;
  top: 6px;
  padding-right: 10px;
  padding-left: 10px;
  width: 160px;
  height: 0;
  overflow: hidden;
  list-style: none;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: rgb(36, 36, 36);
  color: rgb(255, 255, 255);
  border-radius: 10px;
  z-index: 2;
  font-size: 17px;
}

.profile-dropdown.expanded {
  display: flex;
  flex-direction: column;
  border: solid grey 2px;
  position: relative;
  right: 103px;
  top: 6.8px;
  padding-right: 10px;
  padding-left: 10px;
  width: 160px;
  height: 120px;
  overflow: hidden;
  list-style: none;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: rgb(36, 36, 36);
  color: rgb(255, 255, 255);
  border-radius: 10px;
  z-index: 2;
  font-size: 17px;

  transition: height 0.5s, transform 1s;
  transform-origin: top;
  transform: scaleY(1);
}

.profile-dropdown.expanded > * {
  animation: fadeIn 1.2s ease 0s;
}
.profile-dropdown2.expanded > * {
  animation: fadeIn 1.2s ease 0s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.profile-dropdown2 {
  display: flex;
  font-size: 17px;
  flex-direction: column;
  position: absolute;
  top: 49px;
  padding-right: 10px;
  padding-left: 10px;
  width: 80px;
  height: 0;
  overflow: hidden;
  list-style: none;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: rgb(36, 36, 36);
  color: rgb(255, 255, 255);
  border-radius: 10px;
  z-index: 2;
}
.profile-dropdown2.expanded {
  display: flex;
  font-size: 17px;
  flex-direction: column;
  border: solid grey 2px;
  position: absolute;
  top: 50px;
  padding-right: 10px;
  padding-left: 10px;
  width: 80px;
  height: 120px;
  overflow: hidden;
  list-style: none;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: rgb(36, 36, 36);
  color: rgb(255, 255, 255);
  border-radius: 10px;
  z-index: 2;

  transition: height 0.5s, transform 1s;
  transform-origin: top; /* Set the transform origin to top */
  transform: scaleY(1);
}

.profile-dropdown > button {
  color: rgb(0, 0, 0);
  background: none;
  background-color: red;
  margin: 5px 5px;
  width: 100px;
  border-radius: 10px;
}

.profile-dropdown > li:not(.not-you) {
  display: flex;
  justify-content: center;
  font-family: "Source Sans Pro", sans-serif;
  width: 140px;
  /* border-bottom: solid rgb(211, 211, 211) 1px; */
  overflow: auto;
}
.profile-dropdown2 > li:not(.not-you) {
  display: flex;
  font-family: "Source Sans Pro", sans-serif;
  justify-content: center;
  align-items: center;
  width: 93px;
  /* border-bottom: solid rgb(211, 211, 211) 1px; */
}

.profile-dropdown2 > li:not(.not-you):hover {
  background-color: rgb(234, 233, 233);
  color: rgb(0, 166, 255);
  border-radius: 12px;
}
.profile-dropdown > li {
  font-family: Arial, Helvetica, sans-serif;
  display: flex;
  justify-content: space-between;
}

.profile-dropdown2 > li {
  font-family: Arial, Helvetica, sans-serif;
  display: flex;
  justify-content: space-between;
}

.profile-dropdown > button:hover {
  cursor: pointer;
  background-color: rgb(2, 137, 210);
}

.profile-redirect {
  color: rgb(2, 137, 210);
  font-family: "Source Sans Pro", sans-serif;
}

.profile-redirect:hover {
  color: rgb(1, 114, 175);
  cursor: pointer;
  text-decoration: underline;
}

.purps {
  color: rgb(115, 0, 255);
}
.hidden {
  display: none;
}

.navbar {
  position: relative;
  height: 50px;
  width: 100%;
  display: inline-block;
  background-color: rgb(36, 36, 36);
}

.nav-items {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0%;
  margin-top: 0;
  background-color: rgb(36, 36, 36);
}

.nav-right {
  display: flex;
  flex-direction: row;
  height: 50px;
  width: 150px;
  list-style: none;
  justify-content: space-between;
  align-items: center;
  margin-right: 40px;
}
.upload-icon {
  color: white;
}

.nav-left {
  display: flex;
  flex-direction: row;
  margin-left: 80px;
  list-style: none;
  font-size: 30px;
  font-weight: bold;
  text-decoration: none;
  color: rgb(255, 255, 255);
  align-items: center;
}

.you,
.explore,
.prints,
.get-pro {
  margin-left: 30px;
  font-size: 17px;
  font-weight: 100;
}

.you:hover,
.explore:hover,
.prints:hover,
.get-pro:hover {
  cursor: pointer;
  color: rgb(192, 192, 192);
}

li {
  word-wrap: break-word;
  white-space: pre-wrap;
  word-break: break-word;
}

.profile-box {
  height: 40px;
  width: 40px;
}

.profile-box2 {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-pic:hover {
  cursor: pointer;
  opacity: 0.7;
}

.profile-pic {
  width: 40px;
}

.upload-icon:hover {
  cursor: pointer;
  color: rgb(192, 192, 192);
}
.cart-box:hover {
  cursor: pointer;
  color: rgb(192, 192, 192);
}
.fa-upload:hover {
  cursor: pointer;
  color: rgb(192, 192, 192);
}

.profile-box > button {
  width: 40px;
  height: 40px;
  display: inline-block;
  background-color: rgb(255, 255, 255);
  border: none;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-image: url(https://images.unsplash.com/photo-1679246453872-258ee52433eb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80);
}

.profile-box > button:hover {
  background-color: rgb(215, 215, 215);
}

.cart-box {
  position: relative;
  display: flex;
}

.nav-left:visited {
  text-decoration: dotted;
  color: white;
}

.nav-home {
  text-decoration: none;
  color: rgb(255, 255, 255);
}

.nav-home:visited {
  color: rgb(255, 255, 255);
  text-decoration: none;
}

.search-container {
  display: flex;
  height: 35px;
  width: 20%;

  text-align: center;
  align-items: flex-start;
}

.search-form-input {
  border: solid rgb(0, 0, 0) 2px;
  height: 35px;
  width: 100%;
  border: none;
  text-align: start;
  font-size: 14px;
  font-weight: 100;
  padding-left: 15px;
  background-color: rgb(215, 215, 215);
}

.search-form {
  display: flex;
  width: 100%;
  border: none;
}

a {
  text-decoration: none;
  color: white;
}

a:hover {
  color: rgb(169, 169, 169);
  cursor: pointer;
}

.logout-button {
  color: rgb(255, 255, 255);
  background-color: rgb(0, 166, 255);
  border: none;
  margin: 5px 5px;
  width: 90px;
  height: 30px;
  border-radius: 4px;
}

.profile-button {
  color: rgb(0, 0, 0);
  background: none;
  margin: 5px 5px;
  width: 80px;
  border-radius: 10px;
}

.profile-button:hover {
  cursor: pointer;
  background-color: rgb(255, 255, 255);
}

.logout-button:hover {
  cursor: pointer;
  color: rgb(194, 194, 194);
  background-color: rgb(0, 123, 190);
}

.surch {
  display: flex;
  position: relative;
  right: 44px;
  top: 3px;
  height: 45px;
  width: 45px;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
}

.surch:hover {
  background-color: rgb(215, 215, 215);
}

.logo {
  height: 50px;
  width: 80px;
}

.logo:hover {
  cursor: pointer;
  opacity: 0.7;
}
.white {
  color: white;
}

.results2 {
  font-family: "Source Sans Pro", sans-serif;

  font-size: 24px;
}

.nav-left-items {
  display: flex;
}

.nav-left-items-smol {
  display: none;
}
.nav-left-items-smol {
  display: none;
  align-items: center;
}

@media screen and (max-width: 1040px) {
  .nav-left-items {
    display: none;
    flex-direction: column;
    align-items: flex-start;
    margin-right: auto;
  }

  .nav-left-items-smol {
    display: flex;
    flex-direction: row;
    height: 80px;
    width: 40px;
  }

  .nav-left-items-smol:hover {
    cursor: pointer;
  }
}

.hamburger {
  display: flex;
}

.over {
  font-family: "Source Sans Pro", sans-serif;
  padding: 4px;
}

@media screen and (max-width: 700px) {
  .nav-left {
    margin-left: 0;
    height: 100%;
  }

  .nav-home {
    display: hidden;
  }

  .nav-home {
    text-decoration: none;
    color: rgb(255, 255, 255);
    display: none;
  }
  .logo {
    height: 100%;
  }
  .nav-right {
    margin: 0;
    justify-content: space-around;
  }
  .nav-items {
    justify-content: space-between;
  }

  .profile-dropdown.expanded {
    right: 130px;
  }
}
