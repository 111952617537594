.get-pro-container {
  background-color: white;
  display: flex;
  flex-direction: column;
}

.get-pro-image {
  padding-top: 0px;
  color: white;
  display: flex;
  flex-direction: column;
  font-size: 80px;
  align-items: center;
  text-align: center;
  justify-content: center;
  height: 600px;
  background-image: url(https://images.unsplash.com/photo-1516724562728-afc824a36e84?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2371&q=80);
  background-position: center center;
  font-family: Arial, Helvetica, sans-serif;
}

.blue {
  color: rgb(0, 217, 255);
  font-weight: bold;
}

.purple {
  font-weight: bold;
  color: rgb(155, 42, 255);
}

.get-pro-tag {
  font-size: 30px;
  margin-top: 10px;
  margin-bottom: 30px;
}

.get-pro-button {
  font-size: 16px;
  border-radius: 4px;
  border: none;
  height: 70px;
  width: 150px;
  background-color: rgb(0, 166, 255);
  color: rgb(255, 255, 255);
}

.get-pro-button:hover {
  background-color: rgb(0, 143, 220);
  cursor: pointer;
}

.slide-info-l {
  height: 520px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid gainsboro;
}

.slide-info-l-bottom {
  height: 520px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  border-bottom: 1px solid gainsboro;
  background-color: white;
}

.slide-info-l1 {
  height: 520px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid gainsboro;
}

.slide-info- {
  height: 520px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid gainsboro;
}

.slide-info-l-container {
  display: flex;
  height: 400px;
  width: 100%;
  justify-content: space-evenly;
}

.slide-info-l-container-2 {
  display: flex;
  height: 400px;
  width: 100%;
  justify-content: space-evenly;
}

.slide-info-left {
  height: 400px;
  width: 40%;
}

.slide-info-right {
  border-radius: 2px;
  height: 400px;
  width: 40%;
  background-image: url(https://combo.staticflickr.com/ap/build/images/pro/flickrpro-stats-images/bg_en-us.png);
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  animation: slideInRight 1s ease-out;
}

@keyframes slideInRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.slide-info-right2 {
  border-radius: 2px;
  height: 400px;
  width: 40%;
  background-image: url(https://images.unsplash.com/29/night-square.jpg?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2681&q=80);
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.slide-info-right3 {
  border-radius: 2px;
  height: 400px;
  width: 40%;
  background-image: url(https://images.unsplash.com/photo-1629904888780-8de0c7aeed28?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2940&q=80);
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.slide-info-right4 {
  border-radius: 2px;
  height: 400px;
  width: 40%;
  background-image: url(https://images.unsplash.com/photo-1626863905121-3b0c0ed7b94c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2370&q=80);
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.feature-card {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: bold;
  transition: transform 0.2s;
  display: flex;
  flex-direction: column;
  height: 480px;
  width: 18%;

  color: rgb(26, 26, 26);
  border-radius: 12px;
  border: 1px solid rgb(242, 242, 242);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 5px 9px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 6px -3px;
}

.feature-card:hover {
  transform: scale(1.05);
}

.feature-header-image {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 5px 10px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 6px -3px;
  border-radius: 12px;
  width: 100%;
  height: 180px;
  background-size: cover;
  background-repeat: no-repeat;
}

.feature-title {
  font-family: "Source Sans Pro", sans-serif;
  padding-top: 5px;
  display: flex;
  margin: 20px;
  align-items: center;
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 1.5px;
}

.feature-info-container {
  display: flex;
  justify-content: center;
}

.feature-info {
  display: flex;
  align-items: center;
  text-align: left;
  padding: 20px;
  letter-spacing: 1.5px;
  font-size: 17px;
  padding-top: 10px;
}

@media (max-width: 1180px) {
  .imagespace {
    width: 100%;
    font-size: 40px;
    display: flex;
    justify-content: center;
  }

  .slide-info-l {
    width: 100%;
    height: 100%;
  }

  .slide-info-l1 {
    width: 100%;
    height: auto;
  }
  .slide-info-l-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .slide-info-l-container-2 {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
  .info {
    font-size: 28px;
    margin-top: 30px;
    padding-left: 0px;
    padding-right: 0px;
    width: 100%;
    display: flex;
    height: 100%;
  }
  .info-header {
    font-size: 20px;
    margin-bottom: 5px;
  }
  .slide-info-right {
    margin-bottom: 30px;
    height: 125px;
    background-position: 100%;
  }

  .slide-info-right2 {
    margin-bottom: 30px;
    height: 125px;
    width: 60%;
    background-position: 100%;
  }

  .slide-info-right3 {
    margin-bottom: 30px;
    margin-top: 10px;
    height: 125px;
    width: 60%;
    background-position: 100%;
  }

  .slide-info-right4 {
    margin-bottom: 30px;
    height: 125px;
    width: 60%;
    background-position: 100%;
  }
  .slide-info-left {
    display: flex;
    text-align: center;
    height: 100%;
    width: 100%;
  }

  .space-text {
    padding: 0;
  }
  .slide-info-l-bottom {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: 100%;
  }
  .feature-card {
    width: 20%;
    margin-bottom: 30px;
    margin-top: 30px;
  }

  .feature-header-image-box {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 5px 10px -2px,
      rgba(0, 0, 0, 0.3) 0px 3px 6px -3px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    width: 100%;
    height: 180px;
    background-color: rgb(36, 36, 36);
    align-items: center;
    display: flex;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    flex-direction: column;
  }

  .feature-header-image {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 5px 10px -2px,
      rgba(0, 0, 0, 0.3) 0px 3px 6px -3px;
    border-radius: 12px;
    width: 100%;
    height: 180px;

    background-size: cover;
    background-repeat: no-repeat;
  }
}

@media (max-width: 900px) {
  .slide-info-l-bottom {
    display: flex;
    flex-wrap: wrap;
  }
  .feature-card {
    width: 240px;
  }
}
