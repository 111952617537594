.aboutpage-container {
  display: flex;
  flex-direction: row;
  background-color: white;
  font-family: "Source Sans Pro", sans-serif;
}

.aboutpage-left {
  height: 100vh;
  width: 100%;
  padding: 3%;
  padding-left: 1%;
}

.aboutpage-left-link {
  list-style: none;
  margin: 15px;
  border-bottom: 1px solid grey;
  color: rgb(0, 166, 255);
  cursor: pointer;
  padding-bottom: 5px;
  font-size: 18px;
}
.aboutpage-left-link2 {
  list-style: none;
  margin-left: 15px;
  margin-bottom: 10px;
  color: rgb(0, 166, 255);
  cursor: pointer;
  font-size: 18px;
}

.needhelp {
  list-style: none;
  margin: 15px;
  padding-left: 40px;
  color: rgb(0, 0, 0);
  cursor: pointer;
  padding-bottom: 5px;
  padding-top: 10px;
  font-size: 18px;
}

.aboutpage-right {
  height: 100%;
  margin-left: 60px;
  margin-right: 3%;
  font-family: "Source Sans Pro", sans-serif;
}

.aboutpage-header {
  margin-top: 30px;
  font-size: 30px;
  color: rgb(119, 0, 255);
  font-weight: bold;
}

.aboutpage-h2 {
  margin-top: 30px;
  font-size: 24px;
  font-weight: bold;
}
.aboutpage-h2-2 {
  margin-top: 30px;
  font-size: 18px;
  font-weight: bold;
}

.about-info {
  margin: 1% 5%;
  font-size: 18px;
  line-height: 30px;
  font-size: 22px;
}

.about-info-p {
  margin: 1% 5%;
  font-size: 18px;
  line-height: 30px;
}

.aboutpage-image {
  border-radius: 4px;
  margin-bottom: 30px;
  height: 100px;
  width: 100px;
  object-fit: contain;
}

.arrow {
  background: none;
  border: none;
  margin: 5px;
  height: 20px;
  color: rgb(162, 0, 255);
  font-size: 40px;
}

.arrow:hover {
  cursor: pointer;
}

.team-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 30px;
  margin-bottom: 40px;
}
