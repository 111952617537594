.images-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background-color: white;
  height: fit-content;
  width: 100vw;
  padding-left: 5%;
  padding-right: 5%;
}

.image-bar {
  display: flex;
  flex-direction: row;
  width: 100%;
  font-family: "Source Sans Pro", sans-serif;
  border-bottom: 1px solid rgb(225, 225, 225);
}

.all-images {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 10px;
}

.bar-item {
  height: 100%;
  padding: 10px;

  margin: 5px;
  transition: 0.2s ease-in-out;
}

.bar-item:hover {
  background-color: rgb(225, 225, 225);
  cursor: pointer;
}

.active-tab {
  border-bottom: 2px solid rgb(6, 158, 164);
}
.image-ol-img-name {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40%;
  font-size: 30px;
  font-family: "Bebas Neue", sans-serif;
}
.image-overlay:hover {
  cursor: pointer;
  opacity: 0.6;
}

.img-all:hover {
  border: 5px solid black;
}

.image-ol-box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 15px;
}
.image-card {
  background-color: white;
  border-radius: 7px;
}

.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 98%;
  display: flex;
  flex-direction: column;
  font-family: "Source Sans Pro", sans-serif;
  color: white;
  font-size: 15px;
  border-radius: 7px;
  opacity: 0;
  text-shadow: 1px 1px 0 black;
  transition: transform 0.3s ease;
}

.image-card:hover .image-overlay {
  opacity: 1;
  box-shadow: rgba(0, 179, 255, 0.512) 0px 1px 1px,
    rgba(0, 234, 255, 0.455) -1px -1px 1px, rgba(255, 0, 230, 0.4) 0px 1px 1px,
    rgba(212, 0, 255, 0.505) 0px 1px 1px, rgba(0, 0, 0, 0.09) 0px -1px 1px;
}
.spacer {
  height: 100%;
}
.spinner {
  color: rgb(0, 166, 255);
  font-size: 3em;
  text-align: center;
}
.image-card:hover {
  transform: translateY(-5px);
  transition: transform 0.3s ease;
}
.spinner i {
  animation: fa-spin 1s infinite linear;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination button {
  border: none;
  background-color: #ffffff;
  color: #333333;
  font-size: 16px;
  padding: 8px 16px;
  margin: 0 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.like-icon {
  margin-left: 5px;
}

.small-icon {
  font-size: 18px;

  margin-left: 5px;
  color: rgb(0, 166, 255);
}

.spaced {
  margin-left: 5px;
}

.likes-bar {
  display: flex;
  flex-direction: row;
  font-size: 18px;
  align-items: center;
  margin-left: 3px;
  margin-right: 3px;
}

.image-info-ctnr {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 4px;
}

.image-ol-likes {
  display: flex;
  font-size: 18px;
  margin: 5px;
}

.all-image-name {
  justify-content: center;
  width: 110%;
  margin: 5px;
  font-size: 18px;
  white-space: nowrap;
  overflow: hidden;
  margin-left: 10px;

  text-overflow: ellipsis;
}

.pagination button.active {
  background-color: #333333;
  color: rgb(0, 166, 255);
}

@media screen and (max-width: 700px) {
  .images-container {
    padding-left: 0;
    padding-right: 0;
  }
}

@media screen and (max-width: 400px) {
  .images-container {
    padding-left: 0;
    padding-right: 0;
  }

  .profile-name {
    font-size: 10px;
  }
  .all-image-name {
    width: 50%;
  }
  .image-ol-likes {
    width: 50%;
  }
}
