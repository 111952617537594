.about-container {
  height: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Arial, Helvetica, sans-serif;
}

.link-header {
  color: white;
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  border-bottom: 1px rgb(62, 62, 62) solid;
}

.links {
  color: rgb(0, 187, 249);
  padding-top: 20px;
  font-family: Arial, Helvetica, sans-serif;
}

.bottom-nav-item {
  font-weight: 1;
  padding: 5px;
}

.purp {
  color: rgb(140, 42, 232);
}

@media screen and (max-width: 700px) {
  .link-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .bottom-nav-item {
    margin: 0;
    padding: 0;
  }
  .about-container {
    padding-left: 10px;
    padding-right: 10px;
  }
}
