.modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  padding-left: 1rem;
  padding-right: 1rem;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 10000;
  font-family: "Source Sans Pro", sans-serif;
}

.modal button {
  width: 60%;
  padding-left: 2rem;
  padding-right: 2rem;
}

.delete-button-model {
  background-color: rgb(36, 36, 36);
  color: white;
  width: 10rem;
  height: 2rem;
  margin-top: 0.5rem;
  margin-left: 0.5rem;
  -webkit-box-shadow: 1px 0px 0px 2px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 1px 0px 0px 2px rgba(0, 0, 0, 0.75);
  box-shadow: 1px 0px 0px 2px rgba(0, 0, 0, 0.75);
  cursor: pointer;
}
