.create-image-container {
  display: flex;
  flex-direction: row;
  background-color: rgb(179, 179, 179);
}

.create-image-form {
  display: flex;
  flex-direction: column;
  width: 200px;
  background-color: rgb(36, 36, 36);
  color: white;
  height: 1000px;
}

.form-item {
  display: flex;
  flex-direction: column;
  padding: 5px;
}

.form-item > input,
textarea {
  border: none;
  background-color: rgb(74, 74, 74);
  border-radius: 5px;
  color: white;
}

.form-item > label {
  font-size: 18px;
  color: white;
  padding: 2px;
}

.form-button {
  border-radius: 4px;
  width: 70%;
  margin-left: 30px;
  border: none;
  color: rgb(255, 255, 255);
  background-color: rgb(0, 166, 255);
}

.form-button:disabled {
  color: red;
}

.form-button:hover {
  background-color: rgb(0, 118, 182);
  cursor: pointer;
}

.icon {
  border: none;
}

.icon:hover {
  color: rgb(184, 184, 184) y;
  cursor: pointer;
}

.error {
  color: red;
}

.desc-new-image {
  resize: none;
  height: 200px;
}

.preview-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.preview-box {
  height: fit-content;
  width: 330px;
  border: 1px solid rgb(147, 147, 147);
  background-color: rgb(35, 35, 35);
  border-radius: 10px;
  margin: 4px;
}
.x-button {
  display: flex;
  border-radius: 10px;
  border: none;
  margin-left: 5px;
  color: white;
  background-color: rgb(74, 74, 74);
}

.x-button:hover {
  cursor: pointer;
  background-color: rgb(255, 255, 255);
  color: black;
}

.preview-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.preview-image {
  width: 90%;
  height: 320px;
  object-fit: cover;

  margin-top: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 16px, rgba(0, 0, 0, 0.1) 0px 8px 24px,
    rgba(17, 17, 26, 0.1) 0px 16px 56px;
}

.preview-image2 {
  width: 90%;
  height: 320px;
  object-fit: cover;
  border-bottom: rgb(124, 124, 124) 0.5px solid;
  margin-top: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 16px, rgba(0, 0, 0, 0.1) 0px 8px 24px,
    rgba(17, 17, 26, 0.1) 0px 16px 56px;
}
.preview-image22 {
  display: flex;
  color: white;
  justify-content: center;
  align-items: center;
  width: 90%;
  height: 320px;
  object-fit: cover;
  border-bottom: rgb(124, 124, 124) 0.5px solid;
  margin-top: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 16px, rgba(0, 0, 0, 0.1) 0px 8px 24px,
    rgba(17, 17, 26, 0.1) 0px 16px 56px;
}

.preview-image-name {
  justify-content: center;
  text-align: center;
  color: white;
  font-size: 20px;
  margin-top: 20px;
  margin-left: 10px;
  font-family: "Source Sans Pro", sans-serif;
  overflow: auto;
}

.tag-box {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: row;
  margin-top: 10px;

  overflow-y: auto;
}

.tag-button {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: rgb(74, 74, 74);
  border-radius: 12px;
  padding: 2px 8px;
  margin: 5px 5px;
  border: none;
  height: 25px;
}

.tag-button:hover {
  cursor: pointer;
  background-color: rgb(0, 166, 255);
  box-shadow: rgba(149, 157, 165, 0.316) 2px 2px 2px;
}

@media (max-width: 800px) {
  .preview-box {
    max-width: 330px;
    width: 90%;
  }

  .preview-image2 {
    height: 200px;
  }
  .create-image-form {
    width: 40%;
  }
  .preview-box {
    height: fit-content;
  }
  .preview-container {
    flex-direction: column;
    align-items: center;
  }
}
