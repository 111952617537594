.choose-container {
  height: 300px;
  background-color: white;
}

.choose-header {
  font-size: 32px;
  padding-top: 40px;
  padding-bottom: 20px;
  padding-left: 10%;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
}

.choose-desc {
  padding: 5px 10%;
  font-size: 25px;
  width: 400px;
  font-weight: 100;
  font-family: Arial, Helvetica, sans-serif;
}

.choose-button {
  margin-left: 10%;
  margin-top: 60px;
  height: 60px;
  width: 150px;
  border-radius: 12px;
  background: none;
  color: black;
  font-size: 15px;
  font-weight: 600;
  border: 3px solid black;
}

.choose-button:hover {
  background-color: rgb(218, 218, 218);
  cursor: pointer;
}

.image-banner-1 {
  height: 420px;
  background-image: url(https://images.unsplash.com/photo-1500051638674-ff996a0ec29e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2118&q=80);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.image-banner-2 {
  height: 420px;
  background-image: url(https://images.unsplash.com/photo-1531683944026-e56b930467ad?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1364&q=80);
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center center;
}

.info-row {
  background-color: rgb(255, 255, 255);
  display: flex;
  flex-direction: row;
  padding-top: 70px;
  font-family: Arial, Helvetica, sans-serif;
  padding-bottom: 50px;
  padding-left: 10%;
}
.info-row2 {
  background-color: rgb(255, 255, 255);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: 40px;
  font-family: Arial, Helvetica, sans-serif;
  padding-bottom: 50px;
  padding-left: 10%;
  padding-right: 10%;
}

.info-container {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Arial, Helvetica, sans-serif;
}

.info-container2 {
  display: flex;
  justify-content: center;

  font-family: Arial, Helvetica, sans-serif;
  width: 33%;
}

.info {
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 34px;
  font-weight: bold;
  letter-spacing: 1.2px;
  font-family: "Source Sans Pro", sans-serif;
}

.info-header {
  padding-top: 20px;
  font-size: 20px;
  font-weight: 100;
  line-height: 30px;
  width: 90%;
  font-family: "Source Sans Pro", sans-serif;
}

.faq {
  font-size: 24px;
  background-color: white;
  padding: 50px;
  padding-bottom: 0;
  padding-left: 122px;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
}

@media (max-width: 1180px) {
  .info-row {
    flex-direction: column;
    padding: 0;
  }

  .info-row2 {
    flex-direction: column;
    padding: 0;
    padding-bottom: 30px;
  }
  .info-container2 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .info-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .info {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    margin: 0px 10%;
  }

  .choose-desc {
    width: 90%;
    padding: 0;
    margin-left: 5px;
    margin-right: 5px;
    text-align: center;
  }

  .choose-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: fit-content;
    /* margin: 0px 10%; */
  }
  .choose-button {
    margin: 0;
    margin-top: 15px;
    margin-bottom: 10px;
  }

  .choose-header {
    padding: 0;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .faq {
    display: flex;
    justify-content: center;
    padding: 0;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
