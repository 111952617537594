.big {
  height: 300px;
  border-radius: 12px;
  padding: 5px;
  width: 300px;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
}

.wow {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: rgb(255, 255, 255);
}

.such-empty2 {
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
}

.results {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.res-num {
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: 1px;
}

.image-card {
  position: relative;
}

.image-card:hover .info-overlay {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.info-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 92%;
  height: 92%;
  background-color: rgba(255, 255, 255, 0.589);
  display: none;
  z-index: 1;
  color: rgb(0, 0, 0);
  font-size: 16px;
  padding: 10px;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.info-overlay h2 {
  margin-top: 0;
  font-size: 24px;
}

.info-overlay p {
  margin-bottom: 0;
}

.image-name {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 0;
  color: rgb(255, 255, 255);
  background-color: rgb(0, 166, 255);
  padding: 0 15px;
  height: 30px;
  width: 83.5%;
  font-family: "Source Sans Pro", sans-serif;
  border-radius: 4px;
}

.image-description {
  font-size: 16px;
  margin-top: 0;
  font-family: "Source Sans Pro", sans-serif;
  padding: 10px;
}

.image-card:hover .info-overlay {
  display: flex;
  opacity: 1;
}

.fade-in-container {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  animation: fade-in 1s ease-in-out;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
