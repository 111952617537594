.comments-container {
  background-color: rgb(236, 236, 236);
  padding-left: 5%;
  padding-top: 15px;
  font-family: Arial, Helvetica, sans-serif;
}

.single-comment {
  display: flex;
  flex-direction: column;
  background-color: rgb(236, 236, 236);
  border-bottom: 1px solid rgb(205, 205, 205);
  width: 40%;
  padding: 10px;
}

.comment-date {
  font-size: 12px;
  color: rgb(169, 169, 169);
}

.new-comment-text {
  width: 40%;
  height: 80px;
  background-color: white;
  color: black;
  resize: none;
  font-family: "Source Sans Pro", sans-serif;
  line-height: 1.5;
}

.new-comment-form {
  display: flex;
  flex-direction: column;
}

.edit-comment-button {
  border: none;
}

.delete-comment-button {
  border: none;
}
.delete-comment-button:hover,
.edit-comment-button:hover {
  cursor: pointer;
  color: rgb(99, 99, 99);
}

.icon-bar {
  display: flex;
  justify-content: flex-end;
}

.comment-body {
  padding: 10px;
  display: flex;
  flex-direction: column;
  line-height: 1.5;
  width: 100%;
  word-wrap: break-word;
  white-space: pre-wrap;
  word-break: break-word;
}

.new-comment-submit-button {
  padding: 2px;
  margin: 4px;
  width: 100px;
  color: white;
  border-radius: 4px;
  border: none;
  background-color: rgb(0, 166, 255);
}

.new-comment-submit-button:hover {
  color: rgb(197, 197, 197);
  cursor: pointer;
}

.new-comment-submit-button:disabled {
  color: rgb(165, 165, 165);
}

.delete-header {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 100;
  text-align: center;
  font-size: 20px;
  padding-bottom: 20px;
}

.comments-container {
  background-color: rgb(236, 236, 236);
  padding-left: 5%;
  padding-top: 15px;
  font-family: "Source Sans Pro", sans-serif;
}

.comment-owner {
  font-weight: 100;
  font-size: 12px;
}

.comment-name {
  padding-bottom: 3px;
  color: rgb(0, 166, 255);
}
@media (max-width: 600px) {
  .single-comment {
    width: 99%;
    padding: 10px;
    margin: 0;
  }
  .new-comment-text {
    width: 99%;
  }
  .new-comment-submit-button {
    width: 100px;
    margin-left: auto;
  }
  .comment-body {
    padding: 0;
  }
  .comments-container {
    padding: 5%;
  }
}
