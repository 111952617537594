.single-product-container {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(57, 57, 57);
  font-family: Arial, Helvetica, sans-serif;
}

.back-button-container {
  display: block;
  width: 100%;
}

.back-button {
  color: white;
  border: none;
  outline: none;
  background-color: rgb(57, 57, 57);
  padding-top: 10px;
  padding-left: 15px;
  font-size: 15px;
  font-family: "Source Sans Pro", sans-serif;
}

.back-button:hover {
  cursor: pointer;
}

.underline:hover {
  text-decoration: underline;
}

.end {
  padding-left: 50px;
}

.single-image {
  height: 700px;
  width: 700px;
  margin-top: 30px;
  margin-bottom: 40px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.571);
}
.size {
  font-size: 17px;
}

.product-info {
  border-bottom: 1px solid rgb(207, 207, 207);
  background-color: rgb(236, 236, 236);
  padding: 5%;
  padding-bottom: 10px;
  padding-top: 40px;
  width: 50%;
  height: auto; /* Adjusted height property */
}
.owner-link {
  color: rgb(0, 0, 0);
  font-size: 20px;
}

.profile-img {
  height: 65px;
  width: 65px;
  border-radius: 50%;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.571);
}
.single-product-name {
  flex-grow: 1;
  padding: 0px 20px;
  font-size: 25px;
  color: rgb(37, 37, 37);
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 700;
  text-align: center;
}
.user-name {
  display: flex;
  align-items: center;
}

.image-desc {
  padding-top: 10px;
  border-top: 0.5px solid rgb(187, 187, 187);
  line-height: 1.5;
}

@media screen and (min-width: 700px) {
  .image-desc {
    min-height: 100px;
  }
}

@media screen and (min-width: 700px) {
  .image-desc {
    min-height: 100px;
  }
}

.owner-link {
  /* padding-left: 85px; */
  font-family: "Source Sans Pro", sans-serif;
}

.owner-link:hover {
  color: rgb(0, 166, 255);
  cursor: pointer;
}

.liketest {
  width: 50px;
  border: none;
}

.like {
  border: none;
  color: rgb(0, 166, 255);
  background-color: rgb(236, 236, 236);

  padding: 0;
}

.unlike {
  border: none;
  padding: 0;
  color: rgb(0, 166, 255);
  background-color: rgb(236, 236, 236);
}

.like:hover,
.unlike:hover {
  cursor: pointer;
  border-bottom: 1px solid rgb(173, 173, 173);
}

.likes-info {
  padding-top: 5px;
  border: none;
}

.owner-info-row {
  display: flex;
}

.follow-button {
  margin-left: 8px;
  color: white;
  background-color: rgb(0, 166, 255);
  border: none;
  height: 30px;
  width: 100px;
  border-radius: 4px;
}

.follow-button:hover {
  cursor: pointer;
  border: 1px solid rgb(0, 74, 113);
  color: rgb(216, 216, 216);
}

.unfollow-button {
  margin-left: 8px;
  color: white;
  background-color: rgb(0, 166, 255);
  border: none;
  height: 30px;
  width: 100px;
  border-radius: 4px;
}

.unfollow-button:hover {
  cursor: pointer;
  border: 1px solid rgb(0, 74, 113);
  color: rgb(216, 216, 216);
}
.user-card {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-family: "Source Sans Pro", sans-serif;
}

.profile-follows-likes {
  border-bottom: 1px solid rgb(207, 207, 207);
  height: auto;
  background-color: rgb(236, 236, 236);
  font-family: "Source Sans Pro", sans-serif;
  padding-bottom: 10px;
  padding-top: 40px;
  width: 40%;
  display: flex;
  flex-direction: column;
}

.views {
  display: flex;
  flex-direction: row;
  font-family: "Source Sans Pro", sans-serif;
  justify-content: space-around;
}

.box {
  margin: 15px;
  width: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.numbah {
  font-size: 20px;
}

.grey {
  color: rgb(169, 169, 169);
}

.taken-on {
  padding-top: 15px;
  padding-left: 15px;
  font-family: "Source Sans Pro", sans-serif;
  text-align: center;
  padding-right: 5%;
}
.space {
  margin: 5px;
}

.camera-box {
  display: flex;
  flex-direction: row;
}

.taken-on {
  color: rgb(169, 169, 169);
}

@media screen and (max-width: 700px) {
  .single-product-container {
    width: 100%;
  }

  .single-image {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 80%;
  }
  .user-card {
    flex-direction: column;
  }

  .product-info {
    width: 90%;
    padding-top: 10px;
    height: auto;
  }

  .profile-follows-likes {
    width: 100%;
    padding: 0;
  }
  .image-desc {
    padding-left: 0;
    padding-top: 10px;
    border-top: 0.5px solid rgb(187, 187, 187);
    min-height: 100px;
    line-height: 1.5;
  }
  .spaced {
    display: none;
  }
  .owner-link {
    padding-left: 0;
  }
  .profile-follows-likes {
    height: fit-content;
    padding-bottom: 10px;
  }
  .image-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 500px) {
  .user-name {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .end {
    margin: 0;
    padding: 0;
  }

  .single-product-name {
    text-align: center;
  }

  .likes-info {
    width: auto;
    text-align: center;
  }

  .profile-follows-likes {
    width: 100%;
  }

  .owner-info-row {
    justify-content: space-between;
  }
}

@media (min-width: 701px) and (max-width: 940px) {
  .profile-follows-likes {
    padding-top: 0;
  }
}
